import React from 'react'
import { Box, styled, useTheme, Fade, Grid, Paper, Stack, Typography, useMediaQuery,  } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import banner22 from '../images/banner2.jpg';
import './About.css'
import { HouseRounded, Person } from '@mui/icons-material';
import Footer from '../components/Footer';
import { PropertyContext } from '../context';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { keyframes } from '@emotion/react';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const zoomInOut = keyframes`
0% {
    transform: scale(1.2);
    opacity: 2;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const StyledImage = styled('img')(({ theme, isMatch }) => ({
    width: '100%',
    height: isMatch ? '330px' : '100%',
    overflow: 'hidden',
    objectFit: 'cover',
    transition: 'transform 8s cubic-bezier(0.2, 0, 0, 1)', // Zoom-in transition
}));

const CustomSwipeableViews = styled(AutoPlaySwipeableViews)(({ theme, isMatch }) => ({
    '& .react-swipeable-view-container': {
        display: 'flex',

    },
    '& .react-swipeable-view-slide': {
        flexShrink: 0,
        width: '100%',
        height: isMatch ? '330px' : '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        animation: `${zoomInOut} 1s`,
 
    },
}));

const ContainerBox = styled(Box)(({ theme, isMatch }) => ({
    height: isMatch ? 335 : 550,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', // Set the background color here
    overflow: 'hidden'
}));


const useStyles = makeStyles((theme) =>
({
    bannerPaper: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
        background: ` linear-gradient(
          rgb(197 192 192 / 7%), 
          rgb(0 0 0 / 28%)
        ),url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: 25,
        paddingTop: 150
    },
    cityText: {
        fontSize: "8em",
        marginBottom: '2rem',
        textAlign: "center",
        fontWeight: 500,
        color: "white"
    },
    papersecard: {
        marginTop: "4em",
        height: '280px',
        borderRadius: "0px",
        backgroundImage: `url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
})
);

function About() {
    const context = React.useContext(PropertyContext);
    const { SoldSoFarmergeSort, listings } = context;
    const classes = useStyles();
    const theme = useTheme();
    const [checked, setChecked] = React.useState(true);
    const [initialImageLoaded, setInitialImageLoaded] = React.useState(false);
    const isMatch = useMediaQuery('(max-width:600px)')
    const fadeTime = 4500;
    const [activeStep1, setActiveStep1] = React.useState(0);

    const handleStepChange1 = (step: number) => {
        setActiveStep1(step);
    };

    React.useEffect(() => {
        const initialImageTimeout = setTimeout(() => {
            setInitialImageLoaded(true);
        }, 3000); // Set the delay time in milliseconds as needed

        return () => {
            clearTimeout(initialImageTimeout);
        };
    }, []);

    let listingshouse = SoldSoFarmergeSort.filter(item => item.Sold !== "Hide").filter((item) => item.clientImage !== undefined);

    const sortedImages = listingshouse.sort((a, b) => {
        if (a.ciorder === undefined && b.ciorder === undefined) {
            return 0; // Maintain the original order of undefined values
        }
        if (a.ciorder === "" && b.ciorder === "") {
            return 0; // Maintain the original order of undefined values
        }
        if (a.ciorder === undefined) {
            return 1; // Place undefined values at the end of the sorted array
        }
        if (b.ciorder === undefined) {
            return -1; // Place undefined values at the end of the sorted array
        }
        return a.ciorder - b.ciorder; // Sort other values in ascending order
    });


    return (
        <div>
            <Paper className={isMatch ? classes.papersecard : classes.bannerPaper}>
                {/* <div className={classes.cityText}>About</div> */}
                <Grid xs={12} align="center" sx={{ pt: isMatch ? '7em' : 'none' }}>
                    <Typography
                        sx={{
                            ml: isMatch ? 'none' : '0em',
                            fontSize: isMatch ? 'h2.fontSize' : '10rem',
                            fontWeight: 500,
                            color: 'white',
                            mb: isMatch ? 0 : '1.1em',
                            fontFamily: 'Rubik'
                        }}
                    >
                        About
                    </Typography>
                </Grid>
            </Paper>
            {
                isMatch ?
                    <Grid container spacing={2}>
                        <Grid item xs={12} sx={{ mb: '2em' }}>
                            <Box
                                sx={{
                                    mt: '1em',
                                    fontSize: 'h5.fontSize',
                                    fontWeight: 500,
                                    textAlign: 'left',
                                    color: 'rgb(7 6 40 / 87%)',
                                    pl: '1rem',
                                    fontFamily: 'Rubik'
                                }}
                            >
                                Deepti Gupta Real Estate
                            </Box>
                            <Box
                                sx={{
                                    p: '7px 5px 5px 15px',
                                    // pr: '2em',
                                    color: 'rgb(94, 96, 103)',
                                    fontFamily: 'Jaldi',
                                    fontSize: '0.9em',
                                    fontWeight: 400,
                                    lineHeight: '1.467em',
                                    letterSpacing: '0.2px',
                                }}
                            >
                                Deepti Gupta Real Estate offers you the knowledge, experience, & guidance you need to make the process as smooth as possible in your journey to buy or sell home. We have guided clients through every type of market. Customers receive focused attention at every step of the process. We are working primarily in Bothell, Sammamish, Bellevue, Kirkland, Redmond, Millcreek, Lynnwood, Lake Stevens, Renton, Maple Valley, Puyallup and Issaquah.
                            </Box>
                            <Box
                                sx={{
                                    p: '1px 5px 5px 15px',
                                    // pr: '2em',
                                    color: 'rgb(94, 96, 103)',
                                    fontFamily: 'Jaldi',
                                    fontSize: '0.9em',
                                    fontWeight: 400,
                                    lineHeight: '1.467em',
                                    letterSpacing: '0.2px',
                                }}
                            >
                                Our team provides professional services in buying, selling and investing areas ensuring best results for our valued clients. Being a mother myself, I understand the importance of a home that’s more than just brick and mortar; a home that has character and provides the comfort and security of a loving home! I strive towards ensuring that all my clients get the best deals that exceed their expectations and bring them peace of mind.
                                So, what are you waiting for? Reach out to us and We will help you find a house that’ll make you feel at home!
                            </Box>
                            <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                sx={{ pt: '1em', pl: '5px', }}
                            >
                                <Stack direction="row">
                                    <HouseRounded fontSize={isMatch ? "small" : "large"} />
                                    <Typography
                                        sx=
                                        {{
                                            fontSize: '1em',
                                            fontWeight: '600',
                                            pl: '5px'
                                        }}
                                    >
                                        300+ Homes Closed
                                    </Typography>
                                </Stack >
                                <Stack direction="row">
                                    <Person fontSize={isMatch ? "small" : "large"} />
                                    <Typography
                                        sx=
                                        {{
                                            fontSize: '1em',
                                            fontWeight: '600',
                                            pl: '5px'
                                        }}
                                    >
                                        1000+ Happy Clients
                                    </Typography>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid xs={12} align="center" sx={{ mb: '3em', ml: '1em', p: '1em' }}>
                        <CustomSwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeStep1}
                                onChangeIndex={handleStepChange1}
                                enableMouseEvents
                                interval={4000}
                            >
                                {SoldSoFarmergeSort.filter((item) => item.clientImage !== undefined).map((step, index) => (
                                    <ContainerBox sx={{ height: isMatch ? '450px' : '550px' }}>
                                        <Fade key={index} in={activeStep1 === index} timeout={2000}>
                                            <StyledImage src={step.clientImage} alt="image" style={{
                                                width: isMatch ? "100%" : "auto", height: "auto",
                                                transform: activeStep1 === 0 ? 'scale(1.2)' : 'scale(1)', // Zoom-in effect
                                            }} />
                                        </Fade>
                                    </ContainerBox>
                                ))}
                            </CustomSwipeableViews>
                        </Grid>
                    </Grid>
                    :
                    <Grid container spacing={2} wrap="nowrap">
                        <Grid xs={8} sx={{
                            mt: '0em',
                            ml: '8em',
                            mb: '4em'
                        }}
                            spacing={1}>

                            <Box
                                sx={{
                                    mt: '2em',
                                    fontSize: 'h3.fontSize',
                                    fontWeight: 500,
                                    textAlign: 'left',
                                    color: 'rgb(7 6 40 / 87%)',
                                    pl: '10rem',
                                    fontFamily: 'Rubik'
                                }}>
                                Deepti Gupta Real Estate
                            </Box>
                            <Box
                                sx={{

                                    pl: '10rem',
                                    pr: '4em',
                                    color: 'rgb(94, 96, 103)',
                                    fontFamily: 'Jaldi',
                                    fontSize: '1.11em',
                                    fontWeight: 400,
                                    lineHeight: '1.667em',
                                    letterSpacing: '0.4px',
                                }}
                            >
                                Deepti Gupta Real Estate offers you the knowledge, experience, & guidance you need to make the process as smooth as possible in your journey to buy or sell home. We have guided clients through every type of market. Customers receive focused attention at every step of the process. We are working primarily in Bothell, Sammamish, Bellevue, Kirkland, Redmond, Millcreek, Lynnwood, Lake Stevens, Renton, Maple Valley, Puyallup and Issaquah.
                            </Box>
                            <Box
                                sx={{
                                    color: 'rgb(94, 96, 103)',
                                    fontFamily: 'Jaldi',
                                    fontSize: '1.11em',
                                    fontWeight: 400,
                                    lineHeight: '1.667em',
                                    letterSpacing: '0.4px',
                                    pl: '10rem',
                                    pr: '4.3em',

                                }}
                            >
                                Our team provides professional services in buying, selling and investing areas ensuring best results for our valued clients. Being a mother myself, I understand the importance of a home that’s more than just brick and mortar; a home that has character and provides the comfort and security of a loving home! I strive towards ensuring that all my clients get the best deals that exceed their expectations and bring them peace of mind.
                                So, what are you waiting for? Reach out to us and We will help you find a house that’ll make you feel at home!
                            </Box>
                            <Stack direction="row" spacing={2} sx={{ pt: '1em', pl: '10rem', }}>
                                <HouseRounded />
                                <Typography
                                    sx=
                                    {{
                                        fontSize: '1.2em',
                                        fontWeight: '600',
                                    }}
                                >
                                    300+ Homes Closed
                                </Typography>
                                <Person />
                                <Typography
                                    sx=
                                    {{
                                        fontSize: '1.2em',
                                        fontWeight: '600',
                                    }}
                                >
                                    1000+ Happy Clients
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid xs={4} sx={{ mt: '5em', mb: '4em', pr: '2em' }}>
                            <CustomSwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeStep1}
                                onChangeIndex={handleStepChange1}
                                enableMouseEvents
                                interval={4000}
                            >
                                {sortedImages.filter(item => item.clientImage !== null && item.clientImage !== undefined && item.clientImage !== "").map((step, index) => (
                                    <ContainerBox sx={{ height: isMatch ? '450px' : '550px' }}>
                                        <Fade key={index} in={activeStep1 === index} timeout={2000}>
                                            <StyledImage src={step.clientImage} alt="image" style={{
                                                width: isMatch ? "100%" : "auto", height: "auto",
                                                transform: activeStep1 === 0 ? 'scale(1.2)' : 'scale(1)', // Zoom-in effect
                                            }} />
                                        </Fade>
                                    </ContainerBox>
                                ))}
                            </CustomSwipeableViews>

                        </Grid>
                    </Grid>
            }

            <div>
                <Footer />
            </div>
        </div >
    )
}


export default About
