import React, { useState } from 'react'
import { Input, Paper, Typography, Button, Box, Container, Card, Grid, Link, useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import banner22 from '../../images/banner2.jpg';
import one from '../../images/stagingimg/City3.jpg'
import two from '../../images/stagingimg/city4.jpg'
import three from '../../images/stagingimg/city5.jpg'
import four from '../../images/stagingimg/city6.jpg'
import five from '../../images/5.jpg'
import image from '../../images/Deepti.png'
import Gallery from './Gallery';
import { useNavigate } from 'react-router-dom'
import Footer from '../Footer';
import TabStaging from './TabStaging';
import TextField from '@material-ui/core/TextField';
import '../../contact/Email.css'
import '../Singlepage.css';
import '../Detailpage.css';
import emailjs from 'emailjs-com';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Popup from 'reactjs-popup';
import ButtonBase from '@material-ui/core/ButtonBase';
import PhoneIcon from '@material-ui/icons/Phone';
import TestiData from './TestiData';
import Youtube from './Youtube'
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';


const useStyles = makeStyles((theme) =>
({
    bannerPaper: {
        borderRadius: 0,
        height: '100%',
        width: '100%',
        background: ` linear-gradient(
          rgb(197 192 192 / 7%), 
          rgb(0 0 0 / 28%)
        ),url(${banner22})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: '11rem',
        paddingTop: '20rem'
    },

    button1: {
        fontSize: '20px',
        bottom: '-8rem',
        left: '22rem',

    },
    button2: {
        fontSize: '20px',
        bottom: '-8rem',
        right: '-36rem'
    },
    paper: {

        fontSize: "80px",
        fontWeight: 600,
        color: "#fffaf0",
        justifyContent: 'center',
        alignItems: 'center',

    },
    searchroot: {
        paddingTop: '2px 10px',
        display: 'flex',
        alignItems: 'center',
        width: 400,
        margin: 'auto',
        borderRadius: '30px'

    },
    input: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontWeight: 420,
        fontSize: '15px',
        width: 100,

    },
    iconButton: {
        padding: 10,
    },
    card1: {
        borderRadius: 0,
        height: '80%',
        width: '100%',
        background: ` linear-gradient(
          rgb(197 192 192 / 7%), 
          rgb(0 0 0 / 28%)
        )`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding: 150,
        paddingTop: 150
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        padding: "9px 28px"
    },
    icon: {
        marginTop: 50,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    root: {
        display: 'flex',
        '& > *': {

            margin: theme.spacing(1),
        },
    },
    body: {
        marginTop: '8px',
        flex: 1,
        textAlign: 'justify',
        fontSize: '15px',
        fontWeight: '400',

    }
})
);

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function AlertError(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}



// function Youtube() {

//     const [open, setOpen] = React.useState(false);

//     const handleClickOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };


//     const options = {
//         icons: {
//             downloadIcon: null,
//         },
//         buttons: {
//             showDownloadButton: false
//         }
//     }

//     return (
//         <Container className="slider">
//             <SimpleReactLightbox>
//                 <SRLWrapper options={options}>
//                     <Grid container spacing={2}>
//                         {youtubevideos.map((image) => {
//                             return (
//                                 <Grid item xs={12} sm={6} md={4}>

//                                     <Card key={image.id} style={{ width: '100%' }} >
//                                         <ReactPlayer width="100%" url={image.url} controls={true}/>

//                                     </Card>

//                                 </Grid>
//                             )
//                         })}
//                     </Grid>
//                 </SRLWrapper>
//             </SimpleReactLightbox>


//         </Container>
//     )
// }


const Staging = () => {
    const classes = useStyles();
    const theme = useTheme();
    const history = useNavigate();
    const isMatch = useMediaQuery('(max-width:600px )')
    const [openPopup, setOpenPopup] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [openError, setOpenError] = React.useState(false);
    const [loading, setLoading] = React.useState(false)
    const [activeStep, setActiveStep] = React.useState(0);

    const closeModal = () => {
        setOpenPopup(false)
    }

    const handleClick = () => {
        setOpen(true);
    };

    const handleClickError = () => {
        setOpenError(true);
    };


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };


    const handleChange = e => {
        // var {name, value} = e.target
        // setValues({
        //     ...values,
        //     [name]: value,

        // });
    }

    const handleEmailSubmit = e => {
        e.preventDefault();


        emailjs.sendForm(
            'gmail',
            'template_d54orqc',
            e.target,
            'user_c6L1p3GVRkDc5i4AlvRat')
            .then((result) => {
                console.log(result.text);
                handleClick(Alert);

            }, (error) => {
                console.log(error.text);
                handleClickError(AlertError);
            });

        e.target.reset()
    }
    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };

    const options = {
        icons: {
            downloadIcon: null,
        },
        buttons: {
            showDownloadButton: false
        }
    }



    return (
        <div>
            {isMatch ? <TabStaging /> :
                <div>
                    <Paper className={classes.bannerPaper} elevation={0}>
                        <Grid container spacing={8}>
                            <Grid item xs={12} align="center" >
                                <Typography className={classes.paper}
                                >
                                    LIST.STAGE.SELL
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align="center" >
                                <Button
                                    style={{ fontSize: '20px' }}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setOpenPopup(o => !o)}
                                >
                                    GET QUOTATION
                                </Button>
                            </Grid>
                        </Grid>
                        <Dialog open={openPopup} onClose={closeModal} aria-labelledby="form-dialog-title" scroll="paper">
                            <DialogTitle>
                                Request a Staging Quote
                                <IconButton
                                    aria-label="close"
                                    onClick={closeModal}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey[500],
                                    }}
                                >
                                    <Close />
                                </IconButton>
                            </DialogTitle>
                            <form className={classes.form} onSubmit={handleEmailSubmit}>
                                <input type="hidden" name='heading' value="Staging Quotation Request" />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name"
                                    name="name"
                                    autoComplete="name"
                                    className="text_contact"
                                    onChange={handleChange}
                                />
                                <TextField
                                    type=" number"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="number"
                                    label="Phone Number"
                                    name="number"
                                    autoComplete="number"
                                    className="text_contact"
                                    onChange={handleChange}
                                />
                                <TextField
                                    type="address"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="address"
                                    label="Property Address"
                                    name="address"
                                    autoComplete="address"
                                    className="text_contact"
                                    onChange={handleChange}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    disabled={loading}
                                >
                                    Send
                                </Button>
                                <Snackbar open={open} onClose={closeModal}>
                                    <Alert onClose={closeModal} severity="success">
                                        Thankyou for reaching out to us!! Email has been sent to the admin team. They will reach out to you soon.
                                    </Alert>
                                </Snackbar>
                                <Snackbar open={openError} onClose={closeModal}>
                                    <Alert onClose={closeModal} severity="error">
                                        Please try again!
                                    </Alert>
                                </Snackbar>
                            </form>
                        </Dialog>
                        {/* <Popup open={openPopup} closeOnDocumentClick onClose={closeModal}>
                            <Container style={{ padding: "9px 28px" }}>
                                <Card className="container" elevation={5} rounded>
                                    <div className="body_area" >

                                    </div>
                                </Card>
                            </Container>
                        </Popup> */}
                        {/* <Button className={classes.button2} variant="contained" color="secondary">VIEW SERVICE</Button> */}
                    </Paper>
                    <Box display={{ xs: 'none', sm: 'block' }} m={1} style={{ backgroundColor: "white" }} >
                        <Container maxWidth="Lg" style={{ padding: " 0px 20px 20px", borderRadius: "20px", paddingTop: "20px", paddingBottom: "20px", }}>
                            <Typography
                                // component="h3"
                                variant="h4"
                                align="center"
                                color="primary"
                                gutterBottom
                                style={{
                                    fontWeight: '500',
                                    paddingTop: '20px',
                                    color: 'black'
                                }}>
                                Previous Staging Gallery
                            </Typography>
                            <Gallery />
                        </Container>
                    </Box>
                    <Box display={{ xs: 'none', sm: 'block' }} m={1} style={{ padding: " 0px 20px 20px", borderRadius: "20px", backgroundColor: "#D9D9D9" }} >
                        <Container maxWidth="Lg" style={{ paddingTop: '20px' }}  >
                            <Typography
                                style={{
                                    fontWeight: '500',
                                    paddingTop: '20px',
                                    color: 'black'
                                }}
                                variant="h4"
                                align="center"
                                color="text.primary"
                                gutterBottom>
                                Making the difference in WA State Home Staging</Typography>
                            <div style={{ paddingTop: '20px', paddingLeft: '10px', justifyContent: 'center', alignContent: 'center' }}>
                                <Youtube />
                            </div>
                            <Typography variant='h6' align="center" style={{ paddingTop: '20px', paddingBottom: '20px', textAlign: 'center' }}>
                                Deepti Gupta home staging offers a wide variety of services aimed at exceeding our clients’ needs and expectations. With our luxury Staging, we will sell your home faster and maximize potential offers over asking price.
                            </Typography>
                            <Grid item xs={12} >
                                <Link color="primary" style={{ textDecoration: 'none' }} href="tel:425-533-3269">
                                    <ButtonBase disableRipple>
                                        <PhoneIcon color="primary" style={{
                                            width: "1em",
                                            height: "1.5em",
                                        }} />
                                        <Typography variant='h6' style={{ fontSize: "15px", fontWeight: "600" }}>
                                            Please call us: 425-533-3269
                                        </Typography>
                                    </ButtonBase>
                                </Link>
                            </Grid>
                        </Container>
                    </Box>



                    <Box display={{ xs: 'none', sm: 'block' }} m={1}
                        style={{
                            padding: " 0px 20px 20px",
                            borderRadius: "20px",
                            backgroundColor: '#ebebeb'
                        }} >
                        <Container maxWidth="Lg"
                            style={{
                                paddingTop: "20px",
                                backgroundColor: '#ebebeb'
                            }}>
                            <Typography
                                // component="h3"
                                variant="h4"
                                align="center"
                                color="primary"
                                gutterBottom
                                style={{
                                    fontWeight: '500',
                                    paddingTop: '20px',
                                    color: 'black'
                                }}>
                                Client Testimonials
                            </Typography>
                            <TestiData />
                        </Container>
                    </Box>
                    <Box display={{ xs: 'none', sm: 'block' }} m={1} style={{ padding: " 0px 20px 20px", borderRadius: "20px", backgroundColor: "black" }} >
                        <Container maxWidth="Lg" style={{ padding: "0px 50px 30px 50px", paddingTop: "20px", paddingBottom: "20px", paddingLeft: "10px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} align="center">
                                    <Box component="span" sx={{ p: 2 }}  >
                                        <img src={image} width="400px" height="400px" alt="single image" />
                                    </Box>
                                </Grid>


                                <Grid item xs={12} align="center" >
                                    <Typography variant="h4" style={{ color: "white" }}> Deepti Gupta Home Staging</Typography>
                                    <br />
                                    <Typography variant="h6" style={{ color: "white", fontWeight: '400' }}>Deepti Gupta home staging is only for clients who are doing listings with us. Currently we are not providing staging services separately. </Typography>
                                    <br />
                                    <Typography variant="h6" style={{ color: "white", fontWeight: '400' }}>By gaining a deep insight into our clients’ personal tastes, the specific area’s geographical trends, and providing superior customer service, we will always go the extra mile to ensure our clients are fully satisfied with the end result.</Typography>
                                    <br />
                                    <Button variant='contained' color="secondary" onClick={() => history('/Our-Listings')}>
                                        LEARN MORE ABOUT OUR PAST LISTINGS
                                    </Button>
                                </Grid>

                            </Grid>


                        </Container>


                    </Box>


                    {/* <Box display={{ xs: 'none', sm: 'block' }} m={1} style={{ padding: " 0px 20px 20px", borderRadius: "20px", backgroundColor: "white" }} >
                        <Container maxWidth="Lg" style={{ padding: " 0px 20px 20px", borderRadius: "20px", paddingTop: "20px", paddingBottom: "20px", }}>
                            <Typography

                                variant="h4"
                                align="center"
                                color="text.primary"
                                gutterBottom
                                style={{ fontWeight: '500', paddingTop: '20px' }}>
                                Careers
                            </Typography>
                            <Typography
                                align="center"
                                variant="h6"
                                style={{ fontWeight: '500' }}>
                                We are consistently on the look out for new talent as we grow, particularly skilled sales stylists and warehouse employees.
                            </Typography>
                            <br />
                            <Button
                                variant='contained'
                                style={{
                                    backgroundColor: 'black',
                                    color: 'white',
                                    margin: '25px',
                                    left: '38rem'
                                }}
                                onClick={() => history('/JoinOurTeam')}
                            >
                                JOIN OUR TEAM
                            </Button>
                        </Container>
                    </Box> */}

                    <div>
                        <Footer />
                    </div>
                </div>
            }

        </div>
    )
}

export default Staging
