import React, { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { keyframes } from '@emotion/react';
import { Box, Grid, Stack, Typography, useTheme, Card, CardContent, IconButton, Link, Fade, Paper, Fab, useScrollTrigger, Slide, useMediaQuery, Chip } from '@mui/material';
import Image from '../images/stagingimg/Youtube/youtube3.jpg'
import Image1 from '../images/stagingimg/Gallery/b7.jpg'
import Image2 from '../images/stagingimg/Gallery/b11.jpg'
import Image3 from '../images/stagingimg/Gallery/b10.jpg'
import Image4 from '../images/stagingimg/Gallery/b17.jpg'
import Image5 from '../images/stagingimg/b2.jpg'
import Image6 from '../images/stagingimg/Gallery/b12.jpg'
import Image7 from '../images/stagingimg/Gallery/b3.jpg'
import CoverImage from '../images/business.jpg'
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { Facebook, HouseRounded, KeyboardArrowUp, LocationOn, Person, Twitter, YouTube } from '@mui/icons-material';
import ReactPlayer from 'react-player';
import Zillow from '../images/zillow-white.svg';
import ZillowHover from '../images/zillow-custom.svg';
import Youtube from '../components/staging/Youtube';
import { PropertyContext } from '../context';
import Footer from '../components/Footer';
import Loading from '../components/Loading';
import defaultmage from '../images/Image_not_available.png';
// Define the animations


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const zoomInOut = keyframes`
0% {
    transform: scale(1.2);
    opacity: 2;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

const StyledImage = styled('img')(({ theme, isMatch }) => ({
    width: '100%',
    height: isMatch ? '330px' : '100%',
    overflow: 'hidden',
    objectFit: 'cover',
    transition: 'transform 8s cubic-bezier(0.2, 0, 0, 1)', // Zoom-in transition
}));

const CustomSwipeableViews = styled(AutoPlaySwipeableViews)(({ theme, isMatch }) => ({
    '& .react-swipeable-view-container': {
        display: 'flex',

    },
    '& .react-swipeable-view-slide': {
        flexShrink: 0,
        width: '100%',
        height: isMatch ? '330px' : '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        animation: `${zoomInOut} 1s`,
    },
}));

const ContainerBox = styled(Box)(({ theme, isMatch }) => ({
    height: isMatch ? 335 : 550,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', // Set the background color here
    overflow: 'hidden'
}));

const CustomCard = styled(Card)(({ theme }) => ({
    position: 'absolute',
    width: '25%',
    bottom: 0,
    right: '6.3em',
    backgroundColor: '#1A1A1A',
    padding: theme.spacing(1),
    color: theme.palette.common.white,
}));

const ImagePaper = styled(Paper)(({ theme, isMatch }) => ({
    height: isMatch ? '130px' : '150px',
    width: isMatch ? '150px' : '230px',
    margin: isMatch ? '1em' : null,
    borderRadius: '15px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
}));

const CustomFab = styled(Fab)(({ theme }) => ({
    background: '#f16f09',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
        cursor: 'pointer'
    },
}))

const SlideFromBottom = styled(Slide)(({ theme }) => ({
    margin: 0,
    position: 'fixed',
    top: 'auto',
    right: 20,
    bottom: 30,
    left: 'auto',
    backgroundColor: 'none',
    color: 'none',
    animation: 'all 0.5s ease-in-out 0s', // Adjust the animation duration as needed
}), {
    '@keyframes slideFromTop': {
        '0%': {
            transform: 'translateY(0%)',
        },
        '100%': {
            transform: 'translateY(100%)',
        },
    },
});

function ScrollTrigger(props) {
    const { children } = props;

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 400, // Trigger the FAB immediately when scrolling starts
        target: window,
    });

    return (
        <SlideFromBottom direction="up" in={trigger} timeout={600} mountOnEnter unmountOnExit>
            <div>{children}</div>
        </SlideFromBottom>
    )
}


const Home = () => {
    const context = React.useContext(PropertyContext);
    const { SoldSoFarmergeSort, } = context;
    const theme = useTheme();
    const containerRef = useRef(null);
    const [initialImageLoaded, setInitialImageLoaded] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [activeStep, setActiveStep] = React.useState(0);
    const [activeStep1, setActiveStep1] = React.useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const isMatch = useMediaQuery(theme.breakpoints.down("md"));


    const handleStepChange = (step: number) => {
        setActiveStep(step);
    };

    const handleStepChange1 = (step: number) => {
        setActiveStep1(step);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = (event) => {
        const container = containerRef.current;
        const isMouseInsideContainer = container.contains(event.relatedTarget);

        if (isHovered && !isMouseInsideContainer) {
            setIsHovered(false);
        }
    };

    const handleScroll = () => {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        if (scrollTop > 0) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    window.addEventListener('scroll', handleScroll);

    React.useEffect(() => {
        const initialImageTimeout = setTimeout(() => {
            setInitialImageLoaded(true);
        }, 3000); // Set the delay time in milliseconds as needed

        return () => {
            clearTimeout(initialImageTimeout);
        };
    }, []);


    const imageSource = isHovered ? ZillowHover : Zillow;

    const filteredArray = SoldSoFarmergeSort.filter((item) => item.url !== undefined);
    const SoldArray = filteredArray.sort((a, b) => {
        return new Date(b.OfferDate) - new Date(a.OfferDate);
    }).slice(0, 20)

    let listingshouse = SoldSoFarmergeSort.filter(item => item.Sold !== "Hide").filter((item) => item.clientImage !== undefined);

    const sortedImages = listingshouse.sort((a, b) => {
        if (a.ciorder === undefined && b.ciorder === undefined) {
            return 0; // Maintain the original order of undefined values
        }
        if (a.ciorder === "" && b.ciorder === "") {
            return 0; // Maintain the original order of undefined values
        }
        if (a.ciorder === undefined) {
            return 1; // Place undefined values at the end of the sorted array
        }
        if (b.ciorder === undefined) {
            return -1; // Place undefined values at the end of the sorted array
        }
        return a.ciorder - b.ciorder; // Sort other values in ascending order
    });


    console.log("Sold", sortedImages.map((item) => item));

    if (SoldSoFarmergeSort.length === 0) {
        return <Loading />
    }


    return (
        <>
            {SoldSoFarmergeSort.length > 0 &&
                <>
                    <ScrollTrigger>
                        <CustomFab
                            size="medium"
                            color="primary"
                            aria-label="Scroll to top"
                            style={{ display: isVisible ? 'block' : 'none' }}
                            onClick={scrollToTop}>
                            <KeyboardArrowUp />
                        </CustomFab>
                    </ScrollTrigger>
                    <Box position="relative" minHeight={isMatch ? 350 : 550} overflow="hidden">
                        <CustomSwipeableViews
                            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                            index={activeStep}
                            onChangeIndex={handleStepChange}
                            enableMouseEvents
                            interval={4000}
                            isMatch={isMatch}
                        >
                            {images.map((step, index) => (
                                <>
                                    {Math.abs(activeStep - index) <= 6 ? (
                                        <ContainerBox isMatch={isMatch}>
                                            <StyledImage isMatch={isMatch} src={step.image} alt="image" style={{
                                                transform: (activeStep === index || (activeStep === 0 && index === images.length - 1)) ? 'scale(1.2)' : 'scale(1)', // Zoom-in effect
                                            }} />
                                        </ContainerBox>
                                    ) : null}
                                </>
                            ))}
                        </CustomSwipeableViews>
                        {
                            isMatch ?
                                null
                                : <CustomCard>
                                    <CardContent sx={{ flex: '1 0 auto' }}>
                                        <Typography component="div" variant="h4" sx={{ fontFamily: 'Jaldi', }}>
                                            Deepti Gupta
                                        </Typography>
                                        <Typography
                                            sx={{
                                                mt: '0.5em',
                                                fontFamily: 'Jaldi',
                                                fontSize: '1em',
                                                fontWeight: 400,
                                                lineHeight: '1.667em',
                                                letterSpacing: '0.4px',
                                            }}
                                        >
                                            Top Producing Real Estate Agent in WA, First Time Buyer Specialist, Listing Expert, Staging Consultant, Relocation Specialist, Investments Services.
                                        </Typography>
                                    </CardContent>
                                    <Box sx={{ display: 'flex', alignItems: 'center', pl: 1, pb: 1 }}>
                                        <Link href="https://www.facebook.com/dguptahomes/?eid=ARDAoo0zR84TSAdqvnpj9eGeapzmQyI9DA33N8kSw0_HjeEY671_WsIyVRWxmtX0DHDsmjSG1YNWhLGq" underline="none" target="_blank" rel="noopener noreferrer">
                                            <IconButton aria-label="facebook">
                                                <Facebook
                                                    sx={{
                                                        fontSize: '20px', color: '#fff',
                                                        '&:hover': {
                                                            color: '#d3d3d3',
                                                        },
                                                    }} />
                                            </IconButton>
                                        </Link>
                                        <Link href="https://twitter.com/dguptahomes" underline="none" target="_blank" rel="noopener noreferrer">
                                            <IconButton aria-label="twitter">
                                                <Twitter
                                                    sx={{
                                                        fontSize: '20px', color: '#fff',
                                                        '&:hover': {
                                                            color: '#d3d3d3',
                                                        },
                                                    }} />
                                            </IconButton>
                                        </Link>
                                        <Link href="https://www.youtube.com/channel/UCHWBnlM3LyDOJR5IuLbCtiw?view_as=subscriber" underline="none" target="_blank" rel="noopener noreferrer">
                                            <IconButton aria-label="youTube">
                                                <YouTube
                                                    sx={{
                                                        fontSize: '20px', color: '#fff',
                                                        '&:hover': {
                                                            color: '#d3d3d3',
                                                        },
                                                    }} />
                                            </IconButton>
                                        </Link>
                                        <Link href="https://www.zillow.com/profile/Deepti-Gupta/" underline="none" target="_blank" rel="noopener noreferrer">
                                            <IconButton aria-label="zillow" ref={containerRef} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                                <img src={imageSource} alt="" style={{ width: "0,8em", height: "0.8em" }} />
                                            </IconButton>
                                        </Link>
                                    </Box>
                                </CustomCard>
                        }
                    </Box>
                    <Grid container spacing={2} wrap={isMatch ? 'wrap' : "nowrap"} bgcolor='#eceff1'>
                        <Grid item xs={isMatch ? 12 : 8}
                            sx={{
                                mt: '0em',
                                ml: isMatch ? '0em' : '8em',
                                mb: isMatch ? '0em' : '2em',
                            }}
                            spacing={1}>
                            <Box
                                sx={{
                                    mt: isMatch ? null : '1em',
                                    fontSize: isMatch ? 'h5.fontSize' : 'h3.fontSize',
                                    fontWeight: 500,
                                    textAlign: 'left',
                                    color: 'rgb(7 6 40 / 87%)',
                                    pl: isMatch ? '1em' : '10rem',
                                    fontFamily: 'Rubik'
                                }}>
                                Deepti Gupta Real Estate
                            </Box>
                            <Box
                                sx={{
                                    pl: isMatch ? null : '10rem',
                                    pr: isMatch ? null : '4em',
                                    p: isMatch ? '7px 5px 5px 22px' : null,
                                    color: 'rgb(94, 96, 103)',
                                    fontFamily: 'Jaldi',
                                    fontSize: isMatch ? '0.9em' : '1.11em',
                                    fontWeight: 400,
                                    lineHeight: '1.667em',
                                    letterSpacing: '0.4px',
                                }}
                            >
                                Deepti Gupta Real Estate offers you the knowledge, experience, & guidance you need to make the process as smooth as possible in your journey to buy or sell home. We have guided clients through every type of market. Customers receive focused attention at every step of the process. We are working primarily in Bothell, Sammamish, Bellevue, Kirkland, Redmond, Millcreek, Lynnwood, Lake Stevens, Renton, Maple Valley, Puyallup and Issaquah.
                            </Box>
                            <Box
                                sx={{
                                    pl: isMatch ? null : '10rem',
                                    pr: isMatch ? null : '4.3em',
                                    p: isMatch ? '0px 5px 5px 22px' : null,
                                    color: 'rgb(94, 96, 103)',
                                    fontFamily: 'Jaldi',
                                    fontSize: isMatch ? '0.9em' : '1.11em',
                                    fontWeight: 400,
                                    lineHeight: '1.667em',
                                    letterSpacing: '0.4px',

                                }}
                            >
                                Our team provides professional services in buying, selling and investing areas ensuring best results for our valued clients. Being a mother myself, I understand the importance of a home that’s more than just brick and mortar; a home that has character and provides the comfort and security of a loving home! I strive towards ensuring that all my clients get the best deals that exceed their expectations and bring them peace of mind.
                                So, what are you waiting for? Reach out to us and We will help you find a house that’ll make you feel at home!
                            </Box>
                            <>
                                {
                                    isMatch ?
                                        <Stack
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={2}
                                            sx={{ pt: '1em', pl: '5px', }}
                                        >
                                            <Stack direction="row">
                                                <HouseRounded fontSize={isMatch ? "small" : "large"} />
                                                <Typography
                                                    sx=
                                                    {{
                                                        fontSize: '1em',
                                                        fontWeight: '600',
                                                        pl: '5px'
                                                    }}
                                                >
                                                    300+ Homes Closed
                                                </Typography>
                                            </Stack >
                                            <Stack direction="row">
                                                <Person fontSize={isMatch ? "small" : "large"} />
                                                <Typography
                                                    sx=
                                                    {{
                                                        fontSize: '1em',
                                                        fontWeight: '600',
                                                        pl: '5px'
                                                    }}
                                                >
                                                    1000+ Happy Clients
                                                </Typography>
                                            </Stack>
                                        </Stack >
                                        :
                                        <Stack direction="row" spacing={2} sx={{ pt: '1em', pl: '10rem', }}>
                                            <HouseRounded />
                                            <Typography
                                                sx=
                                                {{
                                                    fontSize: '1.2em',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                300+ Homes Closed
                                            </Typography>
                                            <Person />
                                            <Typography
                                                sx=
                                                {{
                                                    fontSize: '1.2em',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                1000+ Happy Clients
                                            </Typography>
                                        </Stack>
                                }
                            </>
                        </Grid>
                        <Grid item xs={isMatch ? 12 : 4} sx={{ p: isMatch ? null : '1em 4.2em 0em 0.3em', m: isMatch ? '1em 1em 2em 1em' : null }}>
                            <CustomSwipeableViews
                                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                index={activeStep1}
                                onChangeIndex={handleStepChange1}
                                enableMouseEvents
                                interval={4000}
                            >
                                {sortedImages.filter(item => item.clientImage !== null && item.clientImage !== undefined && item.clientImage !== "").map((step, index) => (
                                    <ContainerBox sx={{ height: isMatch ? '450px' : '550px' }}>
                                        <Fade key={index} in={activeStep1 === index} timeout={2000}>
                                            <StyledImage src={step.clientImage} alt="image" style={{
                                                width: isMatch ? "100%" : "auto", height: "auto",
                                                transform: activeStep1 === 0 ? 'scale(1.2)' : 'scale(1)', // Zoom-in effect
                                            }} />
                                        </Fade>
                                    </ContainerBox>
                                ))}
                            </CustomSwipeableViews>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                        sx={{ p: isMatch ? '2em 0.9em 2em 2em' : '5em 10em' }}>
                        <Card sx={{ width: '100%', height: isMatch ? '290px' : '700px', p: isMatch ? '1.1em 1em 0em 1em' : '4em 10em', bgcolor: '#E0C387' }} >
                            <ReactPlayer width="100%" height={isMatch ? '260px' : "98%"} style={{ height: isMatch ? '260px' : "98%" }} light={CoverImage} url={`https://dguptahomes.com/wp-content/uploads/2020/09/video-1599539982.mp4`} controls={true} />
                        </Card>
                        <Stack sx={{ mt: isMatch ? '1em' : '4em' }}>
                            <Typography component="div" variant={isMatch ? "h4" : "h3"} sx={{ color: 'rgb(7 6 40 / 87%)', fontFamily: 'Jaldi', }}>Our Listings</Typography>
                        </Stack>
                        <Stack sx={{ mt: isMatch ? '1em' : '4em' }}><Youtube /></Stack>
                        <Stack sx={{ mt: isMatch ? '1em' : '4em' }}>
                            <Typography component="div" variant={isMatch ? "h4" : "h3"} sx={{ color: 'rgb(7 6 40 / 87%)', fontFamily: 'Jaldi', }}>Recent Sold Homes</Typography>
                        </Stack>
                        <Grid
                            container
                            spacing={2}
                            sx={{ mt: isMatch ? '10px' : '4em', pl: isMatch ? '15px' : null }}
                        >

                            {
                                SoldArray.map((card, index) => {
                                    return (
                                        <>
                                            {
                                                isMatch ?
                                                    <Grid xs={6} key={index} display="flex" justifyContent="center" alignItems="center">
                                                        <ImagePaper
                                                            elevation={3}
                                                            sx={{ backgroundImage: `url(${card.url || card.featuredUrl || defaultmage})` }}
                                                            isMatch={isMatch}
                                                        >
                                                            <Chip label={card.city} variant="outlined" size="small" sx={{ background: '#4BB4B4', color: 'white', mt: '12px', border: 'none', borderRadius: '0px', borderBottomRightRadius: '10px', borderTopRightRadius: '10px' }} avatar={<LocationOn />} />
                                                        </ImagePaper>
                                                    </Grid>
                                                    :
                                                    <Grid item xs key={index} >
                                                        <ImagePaper
                                                            elevation={3}
                                                            sx={{ backgroundImage: `url(${card.url || card.featuredUrl || defaultmage})` }}
                                                        >
                                                            <Chip label={card.city} variant="outlined" size="small" sx={{ background: '#4BB4B4', color: 'white', borderRadius: '0px', mt: '10px', border: 'none', borderRadius: '0px', borderBottomRightRadius: '10px', borderTopRightRadius: '10px' }} avatar={<LocationOn />} />
                                                        </ImagePaper>
                                                    </Grid>
                                            }
                                        </>

                                    )
                                })
                            }
                        </Grid>
                    </Grid>

                    <div>
                        <Footer />
                    </div>
                </>
            }
        </>
    );
};

export default Home;


const images = [
    { image: Image },
    { image: Image1 },
    { image: Image2 },
    { image: Image3 },
    { image: Image4 },
    { image: Image5 },
    { image: Image6 },
    { image: Image7 },
];
